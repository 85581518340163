import WhatWeBelieve from '../../modules/ui/pages/WhatWeBelieve';
import withGlobalAppLayout from '../../modules/ui/containers/HOC/withGlobalAppLayout';

export default function index(props: any)
{
    return withGlobalAppLayout(WhatWeBelieve, {
        locale: props.pageContext.locale,
        hideGfLogo: false,
        ...props,
    });
}
