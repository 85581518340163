import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkDown from 'react-markdown';

import { content } from './WhatWeBelieve.markdown';
import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import PageHeader from '../../atoms/PageHeader';
import Head from '../../atoms/Head';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';
import { GFBrand } from '../../models/GFBrand';

class WhatWeBelieve extends React.Component
{
    componentDidMount()
    {
        globalComponentMountOperations.handleRouteComponentMount();
    }

    render()
    {
        const { locale } = this.props;

        return (
            <section className="what-we-believe-container layout-lower-spacing">
                <Head
                    locale={this.props.locale}
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Was wir Glauben' : 'What We Believe'}`}
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forWhatWeBelievePage(this.props.pageContext.locale).getFullUrl()}
                />
                <PageHeader
                    title="what_we_believe"
                    translateTitle={true}
                    showHr={true}
                />
                <div className="article-reader">
                    <ReactMarkDown>
                        {locale === 'de' ? content.de : content.en}
                    </ReactMarkDown>
                </div>
            </section>
        );
    }
}

WhatWeBelieve.propTypes = {
    locale: PropTypes.string,
    pageContext: PropTypes.object,
};

export default WhatWeBelieve;
