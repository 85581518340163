export const content = {
    en: `
### God
We believe in one God the Father Almighty, maker of heaven and earth, and of all things visible and invisible;  

### Jesus
and in one Lord Jesus Christ, the only begotten Son of God,    
Begotten of the Father before all ages, God from God, Light from Light, True God from True God, begotten, not made, being of one substance with the Father, by whom all things were made;    

Who for us men, and for our salvation came down from heaven, and was incarnate by the Holy Ghost of the Virgin Mary, and was made man, and was crucified also for us under Pontius Pilate.    

He suffered and was buried, and the third day he rose again according to the Scriptures, and ascended into heaven, and sits on the right hand of the Father.    
And he shall come again with glory to judge both the quick and the dead: whose kingdom shall have no end.    

### The Holy Spirit
and we believe in the Holy Spirit, the Lord and giver of life,  who proceeds from the Father and the Son, who with the Father and the Son together is worshiped and glorified, 
who has spoken by the Prophets.    

### The Church, Baptism, and Redemption
and we believe in one catholic and apostolic Church.    
We acknowledge one Baptism for the remission of sins.    
And we look for the Resurrection of the dead,    
And the life of the world to come.    
**Amen.**

### Ecumenical Posture

Glaubensfutter takes an ecumenical posture, and therefore we do our best not to privilege one specific Christian confession over the other. The best way to express in general what we defend on a philosophical level is "Mere Christianity" as portrayed by C.S. Lewis in his book by the same name. 

Needless to say, there are many difficulties involved in discerning exactly what "Mere Christianity" is, if for no other reason than that the actual meaning of individual doctrinal points of a creed as well as their orthopraxical implications can be understood differently by individuals. 

For this reason, it is also important to add that we defend a "*historical* Mere Christianity" in the sense that we defend "Mere Christianity" in which the individual doctrinal points are to be understood as grounded in the church's consensual understanding throughout history --  not subject to complete revision and redefinition by the modern world to fit nicely into an otherwise entirely contradictory worldview.
  `,
    de: `
### Gott
Wir glauben an den einen Gott, den Vater, den Allmächtigen, der alles geschaffen hat, Himmel und Erde, die sichtbare und die unsichtbare Welt.

### Jesus
und an den einen Herrn Jesus Christus, Gottes eingeborenen Sohn, aus dem Vater geboren vor aller Zeit: Gott von Gott, Licht vom Licht, wahrer Gott vom wahren Gott, gezeugt, nicht geschaffen, eines Wesens mit dem Vater; durch ihn ist alles geschaffen.    

Für uns Menschen und zu unserm Heil ist er vom Himmel gekommen, hat Fleisch angenommen durch den Heiligen Geist von der Jungfrau Maria und ist Mensch geworden.

Er wurde für uns gekreuzigt unter Pontius Pilatus, hat gelitten und ist begraben worden, ist am dritten Tage auferstanden nach der Schrift und aufgefahren in den Himmel. Er sitzt zur Rechten des Vaters und wird wiederkommen in Herrlichkeit, zu richten die Lebenden und die Toten; seiner Herrschaft wird kein Ende sein.

### Der Heilige Geist
Wir glauben an den Heiligen Geist, der Herr ist und lebendig macht, der aus dem Vater und dem Sohn hervorgeht, der mit dem Vater und dem Sohn angebetet und verherrlicht wird, der gesprochen hat durch die Propheten.

### Die Kirche, Taufe und Erlösung
Wir glauben an die eine, heilige, allgemeine und apostolische Kirche.
Wir bekennen die eine Taufe zur Vergebung der Sünden.
Wir erwarten die Auferstehung der Toten und das Leben der kommenden Welt.    
**Amen.**

### Ökumenische Haltung

Glaubensfutter nimmt eine ökumenische Haltung ein, und deshalb tun wir unser Bestes, um nicht ein bestimmtes christliches Bekenntnis gegenüber einem anderen zu bevorzugen. Der beste Weg, um allgemein auszudrücken, was wir auf philosophischer Ebene verteidigen, ist "Mere Christianity", wie es von C.S. Lewis in seinem gleichnamigen Buch dargestellt wird. 

Es versteht sich von selbst, dass es viele Schwierigkeiten gibt, genau zu bestimmen, was "Mere Christianity" ist, und sei es nur deshalb, weil die tatsächliche Bedeutung einzelner Lehrpunkte eines Glaubensbekenntnisses sowie ihre orthopraxischen Implikationen von jedem Einzelnen unterschiedlich verstanden werden können. 

Aus diesem Grund ist es auch wichtig hinzuzufügen, dass wir ein "*historisches* Mere Christianity" in dem Sinne verteidigen, in dem die einzelnen Lehrpunkte so zu verstehen sind, wie sie im Laufe der Geschichte im kirchlichen Konsens verstanden wurden - und nicht einer vollständigen Revision und Neudefinition durch die moderne Welt unterliegen, damit sie gut in eine ansonsten völlig widersprüchliche Weltanschauung passen.
  `,
};
